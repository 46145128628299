<template>
	<div>
		<div class="table">
			<div class="info">
				<span>
					<span class="back" @click="$router.go(-1)">记录明细</span>
					/ 教职工出入信息
				</span>
			</div>
			<el-table :data="staffData" v-loading="loading">
				<el-table-column type="index" label="序号" align="center"></el-table-column>
				<el-table-column prop="username" label="教师姓名" align="center"></el-table-column>
				<el-table-column prop="type" label="岗位" align="center" :formatter="ttype"></el-table-column>
				<el-table-column prop="arriveTime" label="入校时间" align="center"></el-table-column>
				<el-table-column prop="leaveTime" label="离校时间" align="center"></el-table-column>
				<el-table-column label="有无异常" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.iserror == '1' ? '有' : '无'}}</span>
					</template>
				</el-table-column>
				<el-table-column label="查看详情" align="center">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="detail(scope.row)">详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div>
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total, prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
					style="margin-top:20px;text-align: center;">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		arriveStaffListOfDate
	} from '@/api/user.js'
	export default {
		data() {
			return {
				staffData: [], //教职工出入数据
				date: this.$route.params.date, //跳转的日期参数
				loading: false, //加载框
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
			}
		},
		created() {
			this.getStaffData(1) // 教职工出入列表
		},
		methods: {
			async getStaffData(currpage) { // 教职工出入列表
				this.loading = true;
				const data = {
					page: currpage, //	当前页数
					limit: this.pagesize, // 每页条数
					date: this.date // 日期
				}
				let res = await arriveStaffListOfDate(data); //请求接口
				this.CountNum = parseInt(res.data.countNum); // 总条数
				this.staffData = res.data.list; // 教职工出入列表数据
				this.currpage = currpage; //分页显示当前页
				this.loading = false;
			},
			detail(item) { //跳去详情页
				let userInfo = {
					teacherDate: this.date,
					teacherName: item.username,
					teacherId: item.userid
				};
				this.$router.push({
					path: '/detail',
					query: {
						userInfo: JSON.stringify(userInfo)
					}
				})
			},
			handleCurrentChange(val) { // 分页
				this.getStaffData(val);
			},
      ttype(row, column) { //岗位
      	const mode = row.type
      	if (mode == 1) {
      		return '教师'
      	} else if (mode == 2) {
      		return '副班主任'
      	}  else {
      		return ''
      	}
      },
		}
	}
</script>

<style lang="scss" scoped>
	.table {
		padding: 20px 50px;

		.info {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			font-size: 20px;
			font-weight: bold;
			line-height: 50px;

			.back {
				color: #79740E;
				cursor: pointer;
			}
		}

		.seach {
			display: flex;
			align-items: center;
			width: 500px;
			justify-content: space-between;
		}

	}
</style>
